'use strict';
export function TMerchandise(url) {
  Object.defineProperties(this, {
    "count": {
      get: function() {return this._count;},
      set: function(value) {
        if(!value) {
          throw new TypeError('関連商品点数が指定されていません。');
        }
        this._count = parseInt(value, 10);
      },
      enumerable: true
    },
    "items": {
      get: function() {return this._items;},
      set: function(values) {
        if(!values) {
          throw new TypeError('関連商品情報が指定されていません。');
        }
        this._items = values;
      },
      enumerable: true
    },
    "url": {
      get: function() {return this._url;},
      set: function(value) {
        if(!value) {
          throw new TypeError('関連商品情報の問い合わせ先が指定されていません。');
        }
        this._url = value;
      },
      enumerable: true
    }
  });
  this.url = url;
}
TMerchandise.prototype.init = function() {
  return this.request()
    .then(this.toJSON)
    .then(function(json) {
      this.count = json.items.length;
      if(this.count > 0) {
        this.items = json.items.map(function(item) {
          var m = Object.create(null);
          m.id = item.id;
          m.title = item.title;
          m.masterUrl = item.masterUrl;
          m.imgCnt = item.imgCnt;
          m.imgs = item.imgs;
          m.releaseDate = item.releaseDate;
          m.price = item.price;
          return m;
        });
        this.status = 200;
        this.statusText = 'SUCCESS';
        return this;
      } else {
        throw new RangeError('該当する関連商品がありません。');
      }
    }.bind(this))
    .catch(function(e) {
      var error = Object.create(null);
      error.status = 452;
      error.statusText = 'FAILED';
      error.message = '該当する関連商品情報が取得できませんでした。';
      error.reason = e.message;
      return Promise.reject(JSON.stringify(error));
    });
};
TMerchandise.prototype.toJSON = function(response) {
  return response.json();
}
TMerchandise.prototype.request = function() {
  return fetch(this.url, {cors: 'cors'});
};