exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../img/baseline-error-24px.svg"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../../img/icn_arrow01.png"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../../img/icn_topic01.png"));

// Module
exports.push([module.id, ".kgr-merchandise__container{padding:20px;border:1px solid #ababab;border-radius:22px;background-color:#fff}.kgr-merchandise__row:after{content:'';display:block;clear:both}.kgr-merchandise__cell--thumbnail{float:left;display:table;width:134px;height:134px;margin-bottom:15px;padding:2px;border:1px dotted #ababab;text-align:center}.kgr-merchandise__cell--meta{margin-left:158px}.kgr-merchandise__paragraph--error-message{margin-top:-12px;line-height:1}.kgr-merchandise__paragraph--error-message:before{content:url(" + ___CSS_LOADER_URL___0___ + ");position:relative;top:6px;display:inline-block;width:24px;height:24px;margin-right:4px}.kgr-merchandise__anchor{display:block;width:inherit}.kgr-merchandise__anchor--thumbnail{display:table-cell;text-align:center;vertical-align:middle}.kgr-merchandise__anchor--name{margin-bottom:7px;color:#b20000;font-weight:bold;text-decoration:underline}.kgr-merchandise__anchor--name:hover{opacity:.7}.kgr-merchandise__anchor--readmore{color:#b20000;font-weight:bold;text-align:right;line-height:1}.kgr-merchandise__anchor--readmore:hover{opacity:.7}.kgr-merchandise__anchor--readmore:after{content:url(" + ___CSS_LOADER_URL___1___ + ");position:relative;top:1px;width:6px;height:10px;margin-left:4px}.kgr-merchandise__anchor>img{height:auto;max-width:100%;max-height:134px}.kgr-merchandise__list>li{margin-left:1em;text-indent:-1em}.kgr-merchandise__list>li:before{content:url(" + ___CSS_LOADER_URL___2___ + ");position:relative;top:2px;left:-4px;width:15px;height:14px}\n", ""]);

