'use strict';
export function MerchandiseListitem(rowdata) {
  if(!rowdata) {
    throw new TypeError('rowdataプロパティ値が指定されていません。');
  }
  this.rowdata = rowdata;
};
MerchandiseListitem.prototype.create = function() {
  var df = document.createDocumentFragment();
  var row = document.createElement('div');
  row.className = 'kgr-merchandise__row';
  df.appendChild(row);

  var thumbWrapper = row.cloneNode(false);
  thumbWrapper.className = 'kgr-merchandise__cell';
  thumbWrapper.className += ' kgr-merchandise__cell--thumbnail';
  if(Array.isArray(this.rowdata.imgs)) {
    var thumbnail = document.createElement('img');
    thumbnail.src = this.rowdata.imgs[0].imgSrc;
    thumbnail.alt = this.rowdata.imgs[0].altName.replace(/\s+/g, '').replace('<br>', '');
    if('link' in this.rowdata.imgs[0]) {
      var thumbAnchor = document.createElement('a');
      thumbAnchor.href = this.rowdata.imgs[0].link;
      thumbAnchor.className = 'kgr-merchandise__anchor';
      thumbAnchor.className += ' kgr-merchandise__anchor--thumbnail';
      thumbAnchor.appendChild(thumbnail);
      thumbWrapper.appendChild(thumbAnchor);
    } else {
      thumbWrapper.appendChild(thumbnail);
    }
    df.querySelector('.kgr-merchandise__row').appendChild(thumbWrapper);
  }

  var metaWrapper = row.cloneNode(false);
  metaWrapper.className = 'kgr-merchandise__cell kgr-merchandise__cell--meta';
  if('masterUrl' in this.rowdata) {
    var metaAnchor = document.createElement('a');
    metaAnchor.href = this.rowdata.masterUrl;
    metaAnchor.className = 'kgr-merchandise__anchor';
    metaAnchor.className += ' kgr-merchandise__anchor--name';
    metaAnchor.innerHTML = this.rowdata.title;
    metaWrapper.appendChild(metaAnchor);
  } else {
    var para = document.createElement('p');
    para.innerHTML = this.rowdata.title;
    metaWrapper.appendChild(para);
  }

  if('releaseDate' in this.rowdata && 'price' in this.rowdata) {
    var metaList = document.createElement('ul');
    metaList.className = 'kgr-merchandise__list';
    if('releaseDate' in this.rowdata) {
      var listitem = document.createElement('li');
      var strong = document.createElement('strong');
      strong.appendChild(document.createTextNode('発売月'));
      listitem.appendChild(strong);
      listitem.appendChild(document.createElement('br'));
      listitem.appendChild(document.createTextNode(this.rowdata.releaseDate));
      listitem.normalize();
      metaList.appendChild(listitem);
    }
    if('price' in this.rowdata) {
      var listitem = metaList.firstChild.cloneNode(false);
      var strong = document.createElement('strong');
      strong.appendChild(document.createTextNode('価格'));
      listitem.innerHTML = this.rowdata.price;
      listitem.insertBefore(document.createElement('br'), listitem.firstChild);
      listitem.insertBefore(strong, listitem.firstChild);
      metaList.appendChild(listitem);
    }
    metaWrapper.appendChild(metaList);
  }
  df.querySelector('.kgr-merchandise__row').appendChild(metaWrapper);
  return df;
};