'use strict';
import {MerchandiseListitem} from './top-merchandise-listitem';

export function MerchandiseContent(model) {
  Object.defineProperty(this, 'model', {
    get: function() {return this._model;},
    set: function(values) {
      if(!values) {
        throw new TypeError('modelプロパティ値が指定されていません。');
      }
      this._model = values;
    },
    enumerable: true
  });
  this.model = model;
};
MerchandiseContent.prototype.create = function() {
  var df = document.createDocumentFragment();
  var container = document.createElement('div');
  container.id = 'kgr-widget-merchandise';
  container.className = 'kgr-merchandise__container';
  df.appendChild(container);

  if('items' in this.model) {
    this.model.items.forEach(function(item) {
      var merchandiseListitem = new MerchandiseListitem(item);
      df.firstChild.appendChild(merchandiseListitem.create());
    });
    var readmore = document.createElement('a');
    readmore.href = './relation/'
    readmore.className = 'kgr-merchandise__anchor';
    readmore.className += ' kgr-merchandise__anchor--readmore';
    readmore.textContent = 'more';
    df.firstChild.appendChild(readmore);
  } else if('reason' in this.model) {
    var para = document.createElement('p');
    para.className = 'kgr-merchadise__paragraph';
    para.className += ' kgr-merchadise__paragraph--error-message';
    para.textContent = this.model.message;
    console.error(this.model.reason);
    df.firstChild.appendChild(para);
  }
  return df;
};