'use strict';
import '../sass/top-merchandise.scss';
import {TMerchandise} from './model/tMerchandise';
import {MerchandiseContent} from './view/top-merchandise-content';

(function() {
  window.KAGURA = window.KAGURA || Object.create(null);
  Object.defineProperty(KAGURA, 'status', {
    get: function() {return this._status;},
    set: function(status) {
      this._status = status;
      this[status.toLowerCase()]();
    },
    enumerable: true
  });
  KAGURA.json_request = function() {
    var requestURL = KAGURA.resourceURL.origin + KAGURA.resourceURL.pathname + KAGURA.resourceURL.filename;
    var tMerchandise = new TMerchandise(requestURL);
    tMerchandise.init()
      .then(function(model) {
        if(model.status === 200) {
          KAGURA.merchandise = Object.create(null);
          KAGURA.merchandise.count = model.count;
          KAGURA.merchandise.items = model.items;
        } else if(model.status === 452) {
          KAGURA.failed = Object.create(null);
          KAGURA.failed.message = model.message;
          KAGURA.failed.reason = model.reason;
        }
      });
  };
  KAGURA.render = function() {
    var merchandiseContent;
    if('merchandise' in KAGURA) {
      merchandiseContent = new MerchandiseContent(KAGURA.merchandise);
    } else {
      merchandiseContent = new MerchandiseContent(KAGURA.failed);
    }
    var referenceNode = document.getElementById('top_main');
    referenceNode = referenceNode.querySelector('.block02');
    referenceNode.appendChild(merchandiseContent.create());
    /* EVENT LISTENER */
    referenceNode = document.getElementById('kgr-widget-merchandise');
    for(var i = 0, len = referenceNode.querySelectorAll('.kgr-merchandise__anchor').length; i < len; i++) {
      var element = referenceNode.querySelectorAll('.kgr-merchandise__anchor')[i];
      element.addEventListener('click', KAGURA.onClick, false);
    }
  }
  KAGURA.resourceURL = Object.create(null);
  KAGURA.onDOMContentLoaded = function(e) {
    if(process.env.NODE_ENV === 'production') {
      KAGURA.resourceURL.origin = 'https://assets-kagura.marv.jp';
    } else {
      KAGURA.resourceURL.origin = 'https://assets-kagura-stg.marv.jp';
    }
    KAGURA.resourceURL.pathname = '/items/';
    KAGURA.resourceURL.filename = 'top.json';
    KAGURA.status = 'JSON_REQUEST';
  };
  KAGURA.onLoad = function(e) {
    KAGURA.status = 'RENDER';
  }
  KAGURA.onClick = function(e) {
    e.preventDefault();
    if(e.currentTarget.href.indexOf(location.origin) === -1) {
      window.open(e.currentTarget.href, 'newTab');
    } else {
      location.href = e.currentTarget.href;
    }
  };
  document.addEventListener('DOMContentLoaded', KAGURA.onDOMContentLoaded, false);
  window.addEventListener('load', KAGURA.onLoad, false);
}());